// App.js
import React, { useState, useEffect } from 'react';
import Template1 from './template1';
import Template2 from './template2';
import Template0 from './galleryprofile';
import './webprofile.css';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
  } from "react-router-dom";
const Templaterenderer = () => {
    const [data, setData] = useState(null);
    const [template, setTemplate] = useState(null);
    const { id } = useParams();
  
    const showLoader = () => {
      return (
        <div className="bouncing-ball">
          
        
        <div className="bouncing-ball">
          
          </div>
        </div>
      );
    }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const host = window.location.hostname;
            console.log('subdomain',host)
        const response=await fetch(`https://us-central1-jiffybook-public.cloudfunctions.net/getpublicbusinesswebsectiondata?parameter=${host}`) 
        const result = await response.json();
         console.log('subdatafetched',result.country)
        if(result.country=='IN'){
        
            if(result.country=='IN'){
                const response1=await fetch(`https://us-central1-jiffybook-india.cloudfunctions.net/getsubdomainprofiledata?parameter=${host}`)
                const result1 = await response1.json();
                setData(result1.busdata); // Assuming result.data contains the array of image URLs
        setTemplate(result1.busdata.section1.Template)
            }
        //setData(result.busdata); // Assuming result.data contains the array of image URLs
        //setTemplate(result.busdata.section1.Template)
        /*const manifest = await import('./manifest.json');
       
      manifest.name = result.busdata.busname;
      manifest.icons=[
        {
          "src": result.busdata.buslogo,
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src": result.busdata.buslogo,
          "type": "image/png",
          "sizes": "192x192"
        },
        {
          "src": result.busdata.buslogo,
          "type": "image/png",
          "sizes": "512x512"
        }
      ]*/
       }
        if(result.country=='EE'){
          const response1=await fetch(`https://us-central1-jiffybook-app.cloudfunctions.net/getbusinesswebprofiledata?parameter=${host}`)
          const result1 = await response1.json();
          setData(result1.busdata); // Assuming result.data contains the array of image URLs
  setTemplate(result1.busdata.section1.Template)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    }, []);
  
    if (!data) {
      return  <div style={{marginLeft:"45%",marginTop:'20%'}}>
      <div className="bouncing-ball">
        {showLoader()}
        </div>
     </div>;
    }
    switch (template) {
        case 'A':
          return <Template1 data={data} />;
        case 'B':
          return <Template2 data={data} />;
        default:
          return  <Template0 data={data} />;
      }

};

export default Templaterenderer;
